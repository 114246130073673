<template>
  <div class="container-fluid">
    <list-desktop
      v-if="!$isMobile()"
      :empty="empty"
      :loading="loading"
      :pagination="getP"
      :queriedData="queriedData"
      :data="data"
      :total="total"
      v-on:load_debt_back="loadDebtBack"
      v-on:search_q="searchBack"
      v-on:per_page_selected="nbrPerPageSelected"
      v-on:add_or_edit="addOrEdit"
      v-on:load_debts="loadDebts"
      v-on:delete_debt="deleteDebt"
      v-on:export_pdf="exportPdf"
      v-on:debt_report="debtReport"
    >
    </list-desktop>

    <list-mobile
      v-if="$isMobile()"
      :empty="empty"
      :loading="loading"
      :pagination="getP"
      :queriedData="queriedData"
      :data="data"
      :total="total"
      v-on:add_or_edit="addOrEdit"
      v-on:search_q="searchBack"
      v-on:load_debts="loadDebts"
      v-on:delete_debt="deleteDebt"
      v-on:per_page_selected="nbrPerPageSelected"
      v-on:export_pdf="exportPdf"
      v-on:debt_report="debtReport"
      v-on:load_debt_back="loadDebtBack"
    >
    </list-mobile>

    <mdb-modal
      centered
      size="lg"
      direction="top"
      position="top"
      :show="modalDebt.show"
    >
      <form @submit.prevent="modalDebt.edit ? update() : save()">
        <mdb-modal-header>
          <mdb-modal-title>{{ modalDebt.title }}</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body
          class="modal-tec card-reversed color-normal-reversed"
          v-loading="loadingCreditSelection"
        >
          <fieldset>
            <mdb-row>
              <mdb-col sm="12" md="6">
                <mdb-date-picker-2
                  :validation="
                    $store.state.debt_repayment.errors.start_date ? true : false
                  "
                  :invalidFeedback="
                    $store.state.debt_repayment.errors.start_date
                  "
                  :options="option"
                  v-mask="'####-##-##'"
                  autoHide
                  outline
                  v-model="form.start_date"
                  :defaultDate="form.start_date"
                  label="Date début"
                  title="Date début"
                  @change="endDateChange"
                />
              </mdb-col>
              <mdb-col sm="12" md="6">
                <mdb-date-picker-2
                  :validation="
                    $store.state.debt_repayment.errors.end_date ? true : false
                  "
                  :invalidFeedback="$store.state.debt_repayment.errors.end_date"
                  :options="option"
                  v-mask="'####-##-##'"
                  autoHide
                  outline
                  v-model="form.end_date"
                  :defaultDate="form.end_date"
                  label="Date fin"
                  title="Date fin"
                  @change="startDateChange"
                />
              </mdb-col>

              <mdb-col
                sm="12"
                md="6"
                lg="6"
                v-if="$gate.checkTypeRole(['admin', 'super-admin'])"
              >
                <mdb-select
                  flipOnScroll
                  outline
                  v-model="typeDebtOption"
                  @getValue="getTypeDebt"
                  label="Type de remboursement"
                ></mdb-select>
                <div
                  style="color: #dc3545; font-size: 0.8rem"
                  v-if="$store.state.debt_repayment.errors.customer_id"
                >
                  {{ $store.state.debt_repayment.errors.customer_id }}
                </div>
              </mdb-col>
              <mdb-col sm="12" md="6" lg="6">
                <mdb-select
                  flipOnScroll
                  search
                  v-model="customerOption"
                  @getValue="getCustomer"
                  outline
                  label="Client"
                ></mdb-select>
                <div
                  style="color: #dc3545; font-size: 0.8rem"
                  v-if="$store.state.debt_repayment.errors.customer_id"
                >
                  {{ $store.state.debt_repayment.errors.customer_id }}
                </div>
              </mdb-col>
              <mdb-col
                sm="12"
                md="6"
                lg="6"
                v-if="form.type_debt === 'postpaid'"
              >
                <mdb-select
                  flipOnScroll
                  search
                  multiple
                  selectAll
                  v-model="creditOption"
                  @getValue="getCreditOption"
                  outline
                  label="Ventes à crédit"
                ></mdb-select>
                <div
                  style="color: #dc3545; font-size: 0.8rem"
                  v-if="$store.state.debt_repayment.errors.credit_sales"
                >
                  {{ $store.state.debt_repayment.errors.credit_sales }}
                </div>
                <div>
                  <table class="table">
                    <tr>
                      <td>Montant total ventes</td>
                      <td style="text-align: right">
                        {{ getAmountRemb | formatNumber }} XOF
                      </td>
                    </tr>
                  </table>
                </div>
              </mdb-col>
              <mdb-col sm="12" md="6">
                <mdb-input
                  :validation="
                    $store.state.debt_repayment.errors.amount ? true : false
                  "
                  :invalidFeedback="$store.state.debt_repayment.errors.amount"
                  v-model="form.amount"
                  @change="verifyAmount"
                  size="md"
                  outline
                  label="Montant"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="6">
                <mdb-input
                  :validation="
                    $store.state.debt_repayment.errors.ref ? true : false
                  "
                  :invalidFeedback="$store.state.debt_repayment.errors.ref"
                  v-model="form.ref"
                  size="md"
                  outline
                  label="Reference"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="12">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customSwitches"
                    v-model="form.is_validated"
                  />
                  <label class="custom-control-label" for="customSwitches"
                    >Valider le remboursement</label
                  >
                  <p class="avertissemendebt">
                    <span
                      >En cochant ce bouton, vous stipulez correcte les
                      informations fournies.</span
                    >
                    <br />
                    <span
                      >Ces informations seront envoyées telles qu'elles au
                      client.</span
                    >
                    <br />
                    <span style="color: red"
                      >NB : Aucune modification ne sera possible, une fois
                      coché.</span
                    >
                  </p>
                </div>
              </mdb-col>
            </mdb-row>
          </fieldset>
        </mdb-modal-body>
        <mdb-modal-footer class="">
          <mdb-btn
            size="md"
            color="primary"
            type="submit"
            :disabled="modalDebt.btn"
          >
            <span v-if="!modalDebt.btn">Sauvegarder</span>
            <span
              v-if="modalDebt.btn"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span v-if="modalDebt.btn" class="pl-2">Loading...</span>
          </mdb-btn>
          <mdb-btn size="md" outline="primary" @click="modalDebt.show = false"
            >Fermer</mdb-btn
          >
        </mdb-modal-footer>
      </form>
    </mdb-modal>

    <!-- Import debts -->
    <mdb-modal
      size="md"
      direction="top"
      position="top"
      :show="modalDeptReport.show"
    >
      <form @submit.prevent="downloadReport()">
        <mdb-modal-header>
          <mdb-modal-title>{{ modalDeptReport.title }}</mdb-modal-title
          ><br />
        </mdb-modal-header>
        <mdb-modal-body class="">
          <mdb-row>
            <mdb-col sm="12" md="12">
              <mdb-select
                flipOnScroll
                v-model="typeReportOption"
                @getValue="getTypeReportDebtReport"
                outline
                label="Type de rapport"
              ></mdb-select>
            </mdb-col>
            <mdb-col sm="12" md="6">
              <mdb-date-picker-2
                :options="option"
                v-mask="'####-##-##'"
                autoHide
                outline
                v-model="formDebt.from"
                :defaultDate="formDebt.from"
                label="Date début"
                title="Date début"
              />
            </mdb-col>
            <mdb-col sm="12" md="6">
              <mdb-date-picker-2
                :options="option"
                v-mask="'####-##-##'"
                autoHide
                outline
                v-model="formDebt.to"
                :defaultDate="formDebt.to"
                label="Date fin"
                title="Date fin"
              />
            </mdb-col>
            <mdb-col sm="12" md="6" v-if="formDebt.typeReport === 'detailed'">
              <mdb-select
                flipOnScroll
                search
                multiple
                selectAll
                v-model="customerOption"
                @getValue="getCustomerDebtReport"
                outline
                label="Clients"
              ></mdb-select>
            </mdb-col>
            <mdb-col
              sm="12"
              md="6"
              v-if="$gate.checkTypeRole(['super-admin', 'admin'])"
            >
              <mdb-select
                flipOnScroll
                search
                multiple
                selectAll
                v-model="userOption"
                @getValue="getUserDebtReport"
                outline
                label="Utilisateurs"
              ></mdb-select>
            </mdb-col>
            <mdb-col
              sm="12"
              md="6"
              v-if="$gate.checkTypeRole(['super-admin', 'admin'])"
            >
              <mdb-select
                flipOnScroll
                search
                multiple
                selectAll
                v-model="siteOption"
                @getValue="getSiteDebtReport"
                outline
                label="Sites"
              ></mdb-select>
            </mdb-col>
            <mdb-col sm="12" md="6">
              <mdb-select
                flipOnScroll
                v-model="formatOption"
                @getValue="getFormatDebtReport"
                outline
                label="Format d'export"
              ></mdb-select>
            </mdb-col>
          </mdb-row>
        </mdb-modal-body>
        <mdb-modal-footer class="">
          <mdb-btn
            size="md"
            color="primary"
            type="submit"
            :disabled="modalDeptReport.btn"
          >
            <span v-if="!modalDeptReport.btn">Exporter le rapport</span>
            <span
              v-if="modalDeptReport.btn"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span v-if="modalDeptReport.btn" class="pl-2">Loading...</span>
          </mdb-btn>
          <mdb-btn
            size="md"
            outline="primary"
            @click="modalDeptReport.show = false"
            >Fermer</mdb-btn
          >
        </mdb-modal-footer>
      </form>
    </mdb-modal>
  </div>
</template>

<script>
import download from "../../../services/download-fetch";
import ListDesktop from "./modules/ListDesktop";
import ListMobile from "./modules/ListMobile";

import {
  mdbBtn,
  mdbCol,
  mdbDatePicker2,
  mdbInput,
  mdbModal,
  mdbModalBody,
  mdbModalFooter,
  mdbModalHeader,
  mdbModalTitle,
  mdbRow,
  mdbSelect,
} from "mdbvue";
export default {
  name: "debts",
  components: {
    mdbBtn,
    mdbRow,
    mdbCol,
    mdbInput,
    mdbModal,
    mdbModalTitle,
    mdbModalBody,
    mdbModalHeader,
    mdbModalFooter,
    mdbDatePicker2,
    mdbSelect,
    // mdbSwitch,
    ListDesktop,
    ListMobile,
  },

  data() {
    return {
      option: {
        week: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
        month: [
          "Janvier",
          "Février",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Décembre",
        ],
        placeholder: "choisir une date",
        buttons: {
          ok: "Ok",
          clear: "Effacer",
          cancel: "annuler",
          today: "Aujourd hui",
        },
      },
      loading: false,
      loadingCreditSelection: false,
      dataPagination: {
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [
            { text: "10", value: "10", selected: true },
            { text: "25", value: "25" },
            { text: "50", value: "50" },
            { text: "100", value: "100" },
          ],
          total: 0,
        },
        searchQuery: "",
        propsToSearch: ["start_date", "end_date", "amount", "customer"],
      },

      formatOption: [
        { text: "XLSX", value: "xlsx" },
        { text: "CSV", value: "csv" },
        { text: "PDF", value: "pdf", selected: true },
      ],

      typeReportOption: [
        { text: "Rapport détaillé", value: "detailed", selected: true },
        { text: "Rapport combiné", value: "combined" },
      ],

      empty: false,
      data: {
        columns: [
          {
            label: "#",
            field: "code",
            sort: true,
          },
          {
            label: "Période",
            field: "date",
            sort: true,
          },
          {
            label: "Client",
            field: "customer",
            sort: true,
          },
          {
            label: "Montant",
            field: "amount",
            sort: true,
          },
          {
            label: "Status",
            field: "status",
            sort: true,
          },
          {
            label: "",
            field: "action",
            sort: true,
          },
        ],
        debts: {
          current_page: 1,
          data: [],
        },
      },

      customers: [],
      customerOption: [],

      users: [],
      userOption: [],

      sites: [],
      siteOption: [],

      credits: [],
      creditOption: [],

      typeDebtOptions: [],
      typeDebtOption: [
        {
          text: "Prépayé",
          value: "prepaid",
          selected: false,
        },
        {
          text: "Postpayé",
          value: "postpaid",
          selected: true,
        },
      ],

      form: {
        id: "",
        start_date: "",
        end_date: "",
        ref: "",
        amount: 0,
        customer_id: "",
        type_debt: "postpaid",
        credit_sales: [],
        is_validated: false,
      },

      formDebt: {
        from: this.$moment().format("YYYY-MM-DD"),
        to: this.$moment().format("YYYY-MM-DD"),
        format: "pdf",
        typeReport: "detailed",
        customers: [],
        users: [],
        sites: [],
      },

      modalDebtDetails: {
        show: false,
        edit: false,
        title: "Détails du  remboursement de dette",
        content: {},
      },
      modalDebt: {
        show: false,
        edit: false,
        title: "Ajouter un remboursement de dette",
        content: {},
        btn: false,
      },
      modalDeptReport: {
        show: false,
        edit: false,
        title: "Exporter des rapports",
        content: {},
        btn: false,
      },
    };
  },

  methods: {
    //Fonction de fonctionnement
    addOrEdit(data = null) {
      if (data instanceof Object) {
        this.modalDebt.title = "Modifier un remboursement de dette";
        this.modalDebt.content = data;
        this.modalDebt.edit = true;
        this.formRest(data);
      } else {
        this.modalDebt.title = "Ajouter un remboursement de dette";
        this.modalDebt.content = {};
        this.modalDebt.edit = false;
        this.cleanForm("debt");
      }

      this.modalDebt.show = true;
    },

    cleanForm(type) {
      if (type === "debt") {
        this.form.id = "";
        this.form.start_date = "";
        this.form.end_date = "";
        this.form.customer_id = "";
        this.form.amount = 0;
        this.form.type_debt = "postpaid";
        this.form.is_validated = false;
        this.form.credit_sales = [];
        this.customerOption.forEach((el) => {
          el.selected = false;
        });
        this.creditOption.forEach((el) => {
          el.selected = false;
        });

        this.typeDebtOption.forEach((el) => {
          if (el.value === "postpaid") {
            el.selected = true;
          } else {
            el.selected = false;
          }
        });
      }
    },

    async formRest(data) {
      this.form.id = data.id;
      this.form.start_date = data.start_date;
      this.form.end_date = data.end_date;
      this.form.customer_id = data.customer.id;
      this.form.amount = data.amount;
      this.form.type_debt = data.type_debt;
      this.form.is_validated = data.is_validated;
      this.customerOption.forEach((el) => {
        if (el.value == data.customer.id) {
          el.selected = true;
        } else {
          el.selected = false;
        }
      });
      if (this.form.type_debt === "postpaid") {
        await this.getCreditsSelection({
          from: this.form.start_date,
          to: this.form.end_date,
          is_paid: 0,
          customer: data.customer.id,
        });
      }

      if (this.form.type_debt === "prepaid") {
        this.typeDebtOption.forEach((td) => {
          if (td.value === this.form.type_debt) {
            td.selected = true;
          } else {
            td.selected = false;
          }
        });
      }
      this.form.credit_sales = data.credit_sales;

      this.form.credit_sales.forEach((cs) => {
        this.creditOption.forEach((co) => {
          this.$nextTick(() => {
            if (cs === co.value) {
              co.selected = true;
            } else {
              co.selected = false;
            }
          });
        });
      });
    },

    async getCreditsSelection({ from, to, is_paid, customer }) {
      this.$nprogress.start();
      this.loadingCreditSelection = !this.loadingCreditSelection;

      this.credits.splice(0, this.credits.length);
      this.creditOption.splice(0, this.creditOption.length);
      await this.$store
        .dispatch("credit_sale/findAllSelection", {
          from: from,
          to: to,
          is_paid: is_paid,
          customer: customer,
        })
        .then((response) => {
          this.$nprogress.done();
          this.loadingCreditSelection = !this.loadingCreditSelection;
          this.credits = response.data.data;

          if (this.credits.length === 0) {
            this.$store.commit(
              "debt_repayment/SET_CS",
              "Aucune vente à credit n'a été trouvée."
            );
          } else {
            this.$store.commit("debt_repayment/SET_CS", "");
          }

          this.credits.forEach((credit) => {
            this.creditOption.push({
              text: `${this.$moment(credit.created_at).format(
                "DD/MM/YYYY"
              )} - ${new Intl.NumberFormat("bj-BJ").format(credit.amount)} XOF`,
              value: credit.id,
            });
          });
        })
        .catch((error) => {
          this.$nprogress.done();
          this.loadingCreditSelection = !this.loadingCreditSelection;
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async loadDebts({ page, perPage }) {
      this.$nprogress.start();
      this.loading = !this.loading;
      await this.$store
        .dispatch("debt_repayment/findAll", {
          page: page,
          perPage: perPage,
        })
        .then((response) => {
          this.$nprogress.done();
          this.loading = !this.loading;
          if (response.data.data.data.length > 0) {
            this.empty = false;
          } else {
            this.empty = true;
          }
          this.data.rows = response.data.data;
          this.data.debts = response.data.data;
          this.dataPagination.pagination.currentPage =
            response.data.data.current_page;
          this.dataPagination.pagination.perPage = response.data.data.per_page;
          this.dataPagination.pagination.total = response.data.data.total;
        })
        .catch((error) => {
          this.$nprogress.done();
          this.loading = !this.loading;
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async save() {
      this.$store.commit("debt_repayment/SET_CLEAN");
      this.modalDebt.btn = !this.modalDebt.btn;

      await this.$store
        .dispatch("debt_repayment/save", this.form)
        .then((response) => {
          this.modalDebt.show = false;
          this.modalDebt.btn = !this.modalDebt.btn;
          this.$notify({
            message: response.data.message,
            type: "success",
          });
          this.cleanForm("debt_repayment");
          this.loadDebts({
            page: 1,
            perPage: this.dataPagination.pagination.perPage,
          });
        })
        .catch((error) => {
          this.modalDebt.btn = !this.modalDebt.btn;
          if (error.response.data.errors) {
            this.$store.commit(
              "debt_repayment/SET_CI",
              error.response.data.errors.customer_id
            );
            this.$store.commit(
              "debt_repayment/SET_CS",
              error.response.data.errors.credit_sales
            );
            this.$store.commit(
              "debt_repayment/SET_AMOUNT",
              error.response.data.errors.amount
            );
            this.$store.commit(
              "debt_repayment/SET_SD",
              error.response.data.errors.start_date
            );
            this.$store.commit(
              "debt_repayment/SET_ED",
              error.response.data.errors.end_date
            );
          }

          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async update() {
      this.$store.commit("debt_repayment/SET_CLEAN");
      this.modalDebt.btn = !this.modalDebt.btn;
      await this.$store
        .dispatch("debt_repayment/update", this.form)
        .then((response) => {
          this.modalDebt.show = false;
          this.modalDebt.btn = !this.modalDebt.btn;
          this.$notify({
            message: response.data.message,
            type: "success",
          });
          this.cleanForm("debt");
          this.loadDebts({
            page: this.data.debts.current_page,
            perPage: this.dataPagination.pagination.perPage,
          });
        })
        .catch((error) => {
          this.modalDebt.btn = !this.modalDebt.btn;
          if (error.response.data.errors) {
            this.$store.commit(
              "debt_repayment/SET_CI",
              error.response.data.errors.customer_id
            );
            this.$store.commit(
              "debt_repayment/SET_CS",
              error.response.data.errors.credit_sales
            );
            this.$store.commit(
              "debt_repayment/SET_AMOUNT",
              error.response.data.errors.amount
            );
            this.$store.commit(
              "debt_repayment/SET_SD",
              error.response.data.errors.start_date
            );
            this.$store.commit(
              "debt_repayment/SET_ED",
              error.response.data.errors.end_date
            );
          }
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async deleteDebt(id) {
      let res = await this.$store.dispatch("swal/doYouDelete");
      if (res.isDismissed) {
        return;
      }
      await this.$store
        .dispatch("debt_repayment/delete", id)
        .then((response) => {
          this.$notify({
            message: response.data.message,
            type: "success",
          });
          this.loadDebts({
            page: this.data.debts.current_page,
            perPage: this.dataPagination.pagination.perPage,
          });
        })
        .catch((error) => {
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    verifyAmount() {
      if (this.form.type_debt === "postpaid") {
        if (parseFloat(this.form.amount) > parseFloat(this.getAmountRemb)) {
          this.$store.commit("debt_repayment/SET_AMOUNT", [
            "Le montant renseigné ne correspond pas au montant total des ventes sélectionnées",
          ]);
        } else {
          this.$store.commit("debt_repayment/SET_AMOUNT", [""]);
        }
      }
    },

    async getCustomer(value) {
      this.form.customer_id = value;

      if (
        this.form.start_date &&
        this.form.end_date &&
        this.form.customer_id &&
        this.form.type_debt === "postpaid"
      ) {
        await this.getCreditsSelection({
          from: this.form.start_date,
          to: this.form.end_date,
          customer: this.form.customer_id,
          is_paid: 0,
        });
      }
    },

    async getTypeDebt(value) {
      this.form.type_debt = value;
    },

    getCreditOption(value) {
      this.form.credit_sales = value;
    },

    getCustomerDebtReport(value) {
      this.formDebt.customers = value;
    },
    getTypeReportDebtReport(value) {
      this.formDebt.typeReport = value;

      this.$nextTick(() => {
        this.formDebt.customers = [];

        this.customerOption.forEach((cust) => {
          cust.selected = false;
        });

        if (value === "combined") {
          this.formatOption.push({
            text: "Thermique",
            value: "thermal",
          });
        }
        if (value === "detailed") {
          let input = null;
          this.formatOption.forEach((type) => {
            if (type.value === "thermal") {
              input = this.formatOption.indexOf(type);
            }
          });

          if (input >= 0) {
            this.formatOption.splice(input, 1);
          }
        }
      });
    },
    getUserDebtReport(value) {
      this.formDebt.users = value;
    },

    getSiteDebtReport(value) {
      this.formDebt.sites = value;
    },

    getFormatDebtReport(value) {
      this.formDebt.format = value;
    },

    async loadCustomers() {
      this.customerOption = [];
      this.$nprogress.start();
      await this.$store
        .dispatch("customer/findAll")
        .then((response) => {
          this.$nprogress.done();
          this.customers = response.data.customers
            .filter(
              (customer) => customer.is_active === 1 && customer.approuved === 1
            )
            .filter(
              (customer) => customer.designation.toLowerCase() !== "divers"
            );
          this.customers.forEach((customer) => {
            this.customerOption.push({
              text: customer.designation,
              value: customer.id,
            });
          });
        })
        .catch((error) => {
          this.$nprogress.done();
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async loadSites() {
      if (!this.$gate.checkTypeRole(["super-admin", "admin"])) {
        return (this.sites = []);
      }
      await this.$store
        .dispatch("site/findAll")
        .then((response) => {
          this.sites = response.data.sites;

          this.sites.forEach((site) => {
            this.siteOption.push({
              text: site.name,
              value: site.id,
            });
          });
        })
        .catch((error) => {
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async loadUsers() {
      if (!this.$gate.checkTypeRole(["super-admin", "admin"])) {
        return (this.users = []);
      }
      this.$nprogress.start();
      await this.$store
        .dispatch("user/findAll")
        .then((response) => {
          this.users = response.data.users;
          this.users.forEach((user) => {
            this.userOption.push({
              text: user.lastname + " " + user.firstname,
              value: user.id,
            });
          });
        })
        .catch((error) => {
          this.$nprogress.done();
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async exportPdf(row) {
      let th = 0;
      let doyouPrint = await this.$store.dispatch("swal/doYouPrintPdf");

      if (doyouPrint.isConfirmed) {
        th = 0;
      } else if (doyouPrint.isDenied) {
        th = 1;
      } else {
        return;
      }

      this.$nprogress.start();
      await download({
        url: `/debt-repayments/${row.id}?th=${th}&r=1`,
        isTh: th,
        filename: this.$moment(row.created_at).format("Y-MM-DD") + ".pdf",
      })
        .then(() => {
          this.$nprogress.done();
          this.$notify({
            message: "PDF Généré avec succès",
            type: "success",
          });
        })
        .catch(() => {
          this.$nprogress.done();
          this.$notify({
            message: "Echec durant l'exportation, reessayer ulterieurement",
            type: "danger",
          });
        });
    },

    async downloadReport() {
      this.$nprogress.start();

      if (!this.$gate.checkTypeRole(["super-admin", "admin"])) {
        this.formDebt.users = [];
        this.formDebt.sites = [];

        this.userOption.forEach((cust) => {
          cust.selected = false;
        });
        this.siteOption.forEach((cust) => {
          cust.selected = false;
        });
      }
      await download({
        isTh: this.formDebt.format === "thermal" ? 1 : 0,
        method: "post",
        data: this.formDebt,
        url:
          "/reports/debt-repayments-" +
          this.formDebt.typeReport +
          "?export=" +
          this.formDebt.format,
        filename:
          "debt_repayments_" +
          this.formDebt.typeReport +
          "." +
          this.formDebt.format,
      })
        .then(() => {
          this.$nprogress.done();
          this.$notify({
            message: "Téléchargé avec succès",
            type: "success",
          });
        })
        .catch(() => {
          this.$nprogress.done();
          this.$notify({
            message: "Echec durant le téléchargement",
            type: "danger",
          });
        });
    },

    async endDateChange() {
      if (this.form.start_date && this.form.end_date && this.form.customer_id) {
        await this.getCreditsSelection({
          from: this.form.start_date,
          to: this.form.end_date,
          customer: this.form.customer_id,
          is_paid: 0,
        });
      }
    },
    async startDateChange() {
      if (this.form.start_date && this.form.end_date && this.form.customer_id) {
        await this.getCreditsSelection({
          from: this.form.start_date,
          to: this.form.end_date,
          customer: this.form.customer_id,
          is_paid: 0,
        });
      }
    },

    debtReport() {
      this.modalDeptReport.show = true;
    },

    loadDebtBack(e) {
      this.loadDebts({
        page: e.page,
        perPage: e.perPage,
      });
    },
    searchBack(e) {
      this.dataPagination.searchQuery = e;
    },

    // Systeme de datatable
    totalCalcul() {
      return (this.dataPagination.pagination.total = this.data.debts.total);
    },

    searchQ() {
      if (!this.dataPagination.searchQuery) {
        // return this.pagedData;
        return new Error("Nothing To Search");
      }

      let result = this.data.debts.data.filter((row) => {
        let isIncluded = false;
        for (let key of this.dataPagination.propsToSearch) {
          let rowValue = "";
          rowValue = `${row[key]}`.toString().toLowerCase();
          if (
            rowValue.includes &&
            rowValue.includes(this.dataPagination.searchQuery.toLowerCase())
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      this.dataPagination.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },

    nbrPerPageSelected(e) {
      let nbr = e;
      return this.nbrPerPage(nbr);
    },

    nbrPerPage(nbr) {
      this.loadDebts({
        page: 1,
        perPage: nbr,
      });
      return (this.dataPagination.pagination.perPage = nbr);
    },
    setTotal() {
      if (this.dataPagination.searchQuery === "") {
        this.dataPagination.pagination.total = this.data.debts.total;
      }
    },

    designationFormatter() {
      this.form.designation = this.form.designation.toUpperCase();
    },
  },

  created() {
    this.loadDebts({
      page: this.data.debts.current_page,
      perPage: this.dataPagination.pagination.perPage,
    });
    this.loadCustomers();
    this.loadUsers();
    this.loadSites();
  },

  computed: {
    getAmountRemb() {
      let total = 0;

      if (this.form.type_debt === "postpaid") {
        this.form.credit_sales.forEach((creditOpt) => {
          this.credits.forEach((credit) => {
            if (creditOpt == credit.id) {
              total = parseFloat(total) + parseFloat(credit.amount);
            }
          });
        });
      }

      return total;
    },

    pageData() {
      return this.data.debts.data;
    },

    to() {
      let highBound = this.from + this.dataPagination.pagination.perPage;
      if (this.data.debts.total < parseInt(highBound)) {
        highBound = this.total;
      }
      return parseInt(highBound);
    },
    from() {
      return (
        this.dataPagination.pagination.perPage *
        (this.dataPagination.pagination.currentPage - 1)
      );
    },

    queriedData() {
      let result = this.searchQ();
      this.setTotal();
      return result instanceof Error ? this.pageData : result;
    },

    total() {
      return this.totalCalcul();
    },

    getP() {
      return this.dataPagination;
    },
  },
};
</script>

<style lang="scss">
.avertissemendebt {
  font-size: 0.8em;
}
</style>
